<template>
  <div class="container mt-2">
    <b-overlay
      :show="Show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <!-- Lotto THAI -->
      <div class="header-bg-content mb-2">
        <!-- พิเศษเฉพาะ-->
        <div class="container">

          <div
            class="mb-1"
          >
            <flat-pickr
              v-model="datechange"
              label="วัน/เดือน/ปี"
              class="form-control mt-1"
              placeholder="วัน/เดือน/ปี"
              @input="GetData(),Show = true"
            />
          </div>
          <div class="lotto text-center">
            <div class="mb-1 d-flex justify-content-center">
              <img
                src="th.svg"
                alt=""
                height="20"
              >
              <span class="YrVDG">
                ผลสลากกินแบ่งรัฐบาล {{ ResultThaiDate ? ResultThaiDate : 'วว/ดด/ปปปป' }}
              </span>
            </div>
            <b-row>
              <b-col
                lg="6"
                cols="12"
              >
                <h3>{{ ResultThai.R1 ? ResultThai.R1 : 'xxx' }}</h3>
                <small>รางวัลที่ 1</small>
              </b-col>

              <b-col
                lg="2"
                cols="4"
              >
                <h3>{{ ResultThai.F3N1 ? ResultThai.F3N1 : 'xxx' }} {{ ResultThai.F3N2 ? ResultThai.F3N2 : 'xxx' }}</h3>
                <small>เลขหน้า 3 ตัว</small>
              </b-col>

              <b-col
                lg="2"
                cols="4"
              >
                <h3>{{ ResultThai.B3N1 ? ResultThai.B3N1 : 'xxx' }} {{ ResultThai.B3N2 ? ResultThai.B3N2 : 'xxx' }}</h3>
                <small>เลขท้าย 3 ตัว</small>
              </b-col>

              <b-col
                lg="2"
                cols="4"
              >
                <h3> {{ ResultThai.D2 ? ResultThai.D2 : 'xx' }}</h3>
                <small>สองตัวล่าง</small>
              </b-col>
            </b-row>
          </div>

          <b-row class="mt-2 text-center">
            <b-col
              lg="4"
              cols="12"
              class="p-0"
            >
              <!-- Lotto THAI -->
              <div class="header-bg-content mb-2">
                <!-- พิเศษเฉพาะ-->
                <div class="container">
                  <div class="lotto">
                    <div class="mb-1 d-flex justify-content-center">
                      <img
                        src="https://www.เปิดสอบราชการ.com/img/articles/158634671920200416_154512.png"
                        alt=""
                        height="20"
                      >&nbsp;
                      <span class="YrVDG"> หวย ออมสิน {{ ResultAomDate ? ResultAomDate : 'วว/ดด/ปปปป' }} </span>
                    </div>
                    <b-row>
                      <b-col
                        lg="6"
                        cols="6"
                      >
                        <h3>{{ ResultAom.U3 ? ResultAom.U3 : 'xxx' }}</h3>
                        <small>3ตัวบน</small>
                      </b-col>

                      <b-col
                        lg="6"
                        cols="6"
                      >
                        <h3>{{ ResultAom.D2 ? ResultAom.D2 : 'xx' }}</h3>
                        <small>2ตัวล่าง</small>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              <!-- พิเศษเฉพาะ -->
              </div>
            <!-- End Lotto THAI -->
            </b-col>

            <b-col
              lg="4"
              cols="12"
              class="p-0"
            >
              <!-- Lotto THAI -->
              <div class="header-bg-content mb-2">
                <!-- พิเศษเฉพาะ-->
                <div class="container">
                  <div class="lotto">
                    <div class="mb-1 d-flex justify-content-center">
                      <img
                        src="https://www.tode365.com/wp-content/uploads/2021/08/%E0%B8%98%E0%B8%81%E0%B8%AA.png"
                        alt=""
                        height="20"
                      >&nbsp;
                      <span class="YrVDG"> หวย ธกส. {{ ResultTKSDate ? ResultTKSDate : 'วว/ดด/ปปปป' }}</span>
                    </div>
                    <b-row>
                      <b-col
                        lg="6"
                        cols="6"
                      >
                        <h3>{{ ResultTKS.U3 ? ResultTKS.U3 : 'xxx' }}</h3>
                        <small>3ตัวบน</small>
                      </b-col>

                      <b-col
                        lg="6"
                        cols="6"
                      >
                        <h3>{{ ResultTKS.D2 ? ResultTKS.D2 : 'xx' }}</h3>
                        <small>2ตัวล่าง</small>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              <!-- พิเศษเฉพาะ -->
              </div>
            <!-- End Lotto THAI -->
            </b-col>

            <b-col
              lg="4"
              cols="12"
              class="p-0"
            >
              <!-- Lotto ยี่กี -->
              <div class="header-bg-content mb-2">
                <!-- พิเศษเฉพาะ-->
                <div class="container">
                  <div class="lotto">
                    <div class="mb-1 d-flex justify-content-center">
                      <img
                        src="assets/themes/front2021/images/yigy_special.png"
                        alt=""
                        height="20"
                      >&nbsp;
                      <span class="YrVDG"> จับยี่กี รอบที่ {{ ResultYGRoundNo }} ประจำวันที่ {{ ResultYGLastDate ? ResultYGLastDate : 'วว/ดด/ปปปป' }}</span>
                    </div>
                    <b-row>
                      <b-col
                        lg="6"
                        cols="6"
                      >
                        <h3>{{ ResultYGLast.U3 ? ResultYGLast.U3 : 'xxx' }}</h3>
                        <small>3ตัวบน</small>
                      </b-col>

                      <b-col
                        lg="6"
                        cols="6"
                      >
                        <h3>{{ ResultYGLast.D2 ? ResultYGLast.D2 : 'xx' }}</h3>
                        <small>2ตัวล่าง</small>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              <!-- พิเศษเฉพาะ -->
              </div>
            <!-- End Lotto THAI -->
            </b-col>
          </b-row>
          <!-- ---- -->
          <div class="section-wrap section-normal">
            <div class="section-title-l">
              หวยหุ้นต่างประเทศ ประจำวันที่ {{ dateshow }}
            </div>
            <div class="row gutters-10">
              <div
                v-for="(item) in list"
                :key="item"
                class="col-md-4 col-lg-2"
              >
                <div class="box-lotto">
                  <div class="box-title">
                    <img
                      :src="`/assets/themes/front2021/images/${item.picture}`"
                      class="img-title"
                    ><strong>{{ item.name }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="item.result1 > 0"
                        class="lot-num"
                      >
                        {{ item.result1 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="item.result2 > 0"
                        class="lot-num"
                      >
                        {{ item.result2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ยี่กี -->
          <div class="section-wrap section-normal mt-1">
            <div class="section-title-l">
              จับยี่กี ประจำวันที่ {{ ResultYGTodayDate }}
            </div>
            <div class="row gutters-10">
              <div
                v-for="(item , index) in ResultYGToday"
                :key="item"
                class="col-md-4 col-lg-2"
              >
                <div class="box-lotto">
                  <div class="box-title yigy264">
                    <img
                      src="assets/themes/front2021/images/yigy_special.png"
                      class="img-title"
                    ><strong>จับยี่กี รอบที่ {{ index + 1 }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="item.U3 > 0"
                        class="lot-num"
                      >
                        {{ item.U3 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="item.D2 > 0"
                        class="lot-num"
                      >
                        {{ item.D2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    <!-- พิเศษเฉพาะ -->
    </b-overlay>
  </div>
  <!-- End Lotto THAI -->
</template>

<script>
import {
  // BButton,
  BRow, BCol,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import HomeBanner from './HomeBanner.vue'
// import HomeSponsor from './HomeSponsor.vue'
import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // BButton,
    // HomeBanner,
    // HomeSponsor,
    BRow,
    flatPickr,
    BCol,
    BOverlay,
    BIconController,
    // BTable,
    // BAvatar,
    // BBadge,
  },
  data() {
    return {
      slide: 0,
      Show: true,
      sliding: null,
      date: momenttz().format('YYYY-MM-DD'),
      dateshow: null,
      datechange: null,
      ResultThai: {},
      ResultThaiDate: null,
      ResultAom: {},
      ResultAomDate: null,
      ResultTKS: {},
      ResultTKSDate: null,
      list: [],
      ResultYGLast: {},
      ResultYGLastDate: null,
      ResultYGRoundNo: null,
      ResultYGToday: {},
      ResultYGTodayDate: momenttz().format('DD/MM/YYYY'),
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/salon/1.jpg') },
        { img: require('@/assets/images/salon/2.jpg') },
        { img: require('@/assets/images/salon/3.jpg') },
        { img: require('@/assets/images/salon/4.jpg') },
        { img: require('@/assets/images/salon/5.jpg') },
        { img: require('@/assets/images/salon/6.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
        pagination: {
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        },
      },
      fields: [
        { key: 'round', label: 'รอบ' },
        { key: '3', label: '3ตัวบน' },
        { key: '2', label: '2ตัวล่าง' },
      ],
      items: [
        {
          round: 'หุ้นไทยเช้า',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเที่ยง',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยบ่าย',
          3: 'xxx',
          2: 'xx',
        },
        {
          round: 'หุ้นไทยเย็น',
          3: 'xxx',
          2: 'xx',
        },
      ],
    }
  },
  async mounted() {
    this.GetData()
    this.GetResultThai(5, null)
    this.GetResultAom(105, null)
    this.GetResultTKS(55, null)
    this.GetResultYGLast()
    this.GetResultYG_Today()
  },
  methods: {
    async GetData() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/Reward/LottoList',
        )

        if (ResData) {
          this.Show = false
          this.list = ResData
          this.GetResult()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultThai(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultThai = ResData.mes
          this.ResultThaiDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultAom(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultAom = ResData.mes
          this.ResultAomDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultTKS(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultTKS = ResData.mes
          this.ResultTKSDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResult() {
      this.dateshow = momenttz(this.date).format('DD/MM/YYYY')
      if (this.datechange) {
        this.date = this.datechange
      }

      // eslint-disable-next-line no-restricted-syntax
      for await (const item of this.list) {
        const params = {
          LottoHead: item.HeadID,
          date: this.date,
        }
        try {
          const { data: ResData } = await this.$http.get(
            'https://khunyingapi.linelotto.vip/api/result/GetResultReward',
            { params },
          )
          if (ResData.success) {
            let Index = null
            // console.log(ResData.mes)
            Index = this.list.findIndex(
              ({ HeadID }) => HeadID === ResData.mes.LottoHead,
            )
            this.list[Index].result1 = ResData.mes.U3
            this.list[Index].result2 = ResData.mes.D2
          }
        } catch (e) {
          console.log(e)
          // return null
        }
      }
    },
    async GetResultYGLast() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/result/ShowLastYG',
        )

        if (ResData.success) {
          this.ResultYGLast = ResData.mes
          this.ResultYGRoundNo = ResData.RoundNo
          this.ResultYGLastDate = momenttz(ResData.mes.RoundData.CloseDate).format('DD/MM/YYYY')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultYG_Today() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/result/YGToDay',
        )

        if (ResData.success) {
          this.ResultYGToday = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
.balance {
  border: 1px solid rgb(255, 123, 123);
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  background-image: url(https://fn.dmpcdn.com/Components/HeaderMenu/images/red-defual-header.svg);
  background-position: left bottom;
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: unset;
}
body,
html {
  padding: 0;
  margin: 0;
}
.img-mark {
  border-radius: 7px;
}
h3 {
  font-size: 1.8rem;
  font-weight: bold;
}
.header-bg {
  background-color: rgb(255, 255, 255);
  padding: 4px;
}
.header-bg-content {
  background-color: #ffffff;
}
.YrVDG {
  font-size: 1.2rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(36, 36, 36);
}
@media (max-width: 574px) {
  .YrVDG {
    font-size: 1.1rem;
    font-weight: 700;
  }
}
.text-card {
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.container-spon {
  margin: auto;
  max-width: 640px;
}

.bg-balance {
  padding: 10px 25px 10px 25px;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgb(255, 110, 110);
}

.bg-balance2 {
  border: 1px solid rgb(255, 0, 0);
  border-radius: 10px;
  background-image: url(/images/bg-header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  border-radius: 9px;
}

.btn-menu {
  border: 1px solid rgb(255, 123, 123);
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  border-radius: 10px;
  background-color: #b62525;
}

.lotto {
  /* text-align: center; */
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 0.3rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
